import React from 'react';
import { Link } from "react-router-dom"; 
import Navbar from './global-components/navbar';
import { Helmet } from 'react-helmet';
import PortfolioV1 from './section-components/portfolio-v1';
import CallToActonV2 from './section-components/call-to-action-v2';
import Footer from './global-components/footer';

const Portfolio_V1 = () => {
    return <div>
<Helmet>
        <title>Portfolio | Flying Dragon Technical Works</title>
        <meta
          name="description"
          content="Welcome to Flying Dragon Technical Services L.L.C, a pinnacle of precision and expertise in the technical services landscape. With a comprehensive suite of offerings ranging from meticulous false ceiling installations, sanitary fittings, and facilities management to artistic engraving, painting, and carpentry works, we pride ourselves on delivering excellence at every juncture."
        />
        <meta
          name="keywords"
          content=""
        />
      </Helmet>

        <Navbar />
        <div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "} data-bs-bg={"assets/img/bg/23.jpg"} > 
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
					<div className="ltn__breadcrumb-inner">
					<h1 className="page-title">Portfolio Works</h1>
					<div className="ltn__breadcrumb-list">
						<ul>
						<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
						<li>Portfolio Works</li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
		</div>
        
        <PortfolioV1 />
        <CallToActonV2 />
        <Footer />
    </div>
}

export default Portfolio_V1

