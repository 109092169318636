import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pb-115 go-top">
	<div className="container">
	<div className="row">
		<div className="col-lg-5 align-self-center">
		<div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
			<img src={publicUrl+"assets/img/product-3/1.jpg"} alt="Image" />
		</div>
		</div>
		<div className="col-lg-7 align-self-center">
		<div className="about-us-info-wrap">
			<div className="section-title-area ltn__section-title-2--- mb-20">
			
			<h1 className="section-title">False ceiling & <span>light partition installation</span></h1>
			<p>Transform your space with our specialized false ceiling installations. Paired with innovative lighting solutions, we create
ambiance, optimize acoustics, and enhance functionality, ensuring a harmonious blend of aesthetics and practicality.
Tailored to your spatial needs, our partition installations redefine interiors. Whether for offices, hospitality, or retail spaces,
we craft partitions that maximize space utilization, privacy,
and aesthetic appeal, reflecting your unique requirements</p>
			</div>
			
			<div className="btn-wrapper animated">
			<Link to="/about" className="theme-btn-1 btn btn-effect-1 text-uppercase">About Us</Link>
			</div>
		</div>
		</div>
	</div>
	</div>
</div>
        }
}

export default AboutV5