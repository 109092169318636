import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from './components/home-v2';

import Ser1 from './components/False-ceiling-light-partition-installation';
import Ser2 from './components/Sanitary-installation-pipes-repairing';
import Ser3 from './components/Facilities-management';
import Ser4 from './components/Floor-wall-tiling-works';
import Ser5 from './components/Engraving-ornamentation-works';
import Ser6 from './components/Painting-contracting';
import Ser7 from './components/Plaster-works';
import Ser8 from './components/Glass-aluminum-installation';
import Ser9 from './components/Electrical-fittings';
import Ser10 from './components/Wallpaper-fixing-works';
import Ser11 from './components/Carpentry-wood-flooring-works';







import About from './components/about';
import Service from './components/service';
import ServiceDetails from './components/service-details';
import Portfolio from './components/portfolio';
import PortfolioV2 from './components/portfolio-v2';
import PortfolioDetails from './components/portfolio-details';
import Team from './components/team';
import TeamDetails from './components/team-details';
import Faq from './components/faq';
import ComingSoon from './components/coming-soon';
import Error  from './components/404';
import Location  from './components/location';

import ProdductDetails from './components/product-details';


import Contact from './components/contact';



class Root extends Component {
    render() {
        return(
            <Router basename="/">
	                <div>
	                <Switch>
	                    <Route exact path="/" component={Home} />
                        <Route path="home-v2" component={Home} />
                        
                        <Route path="/False-ceiling-light-partition-installation" component={Ser1} />
                        <Route path="/Sanitary-installation-pipes-repairing" component={Ser2} />
                        <Route path="/Facilities-management" component={Ser3} />
                        <Route path="/Floor-wall-tiling-works" component={Ser4} />
                        <Route path="/Engraving-ornamentation-works" component={Ser5} />
                        <Route path="/Painting-contracting" component={Ser6} />
                        <Route path="/Plaster-works" component={Ser7} />
                        <Route path="/Glass-aluminum-installation" component={Ser8} />
                        <Route path="/Electrical-fittings" component={Ser9} />
                        <Route path="/Wallpaper-fixing-works" component={Ser10} />
                        <Route path="/Carpentry-wood-flooring-works" component={Ser11} />
                    
                        
                        <Route path="/about" component={About} />
                        <Route path="/service" component={Service} />
                        <Route path="/service-details" component={ ServiceDetails } />
                        <Route path="/portfolio" component={ Portfolio } />
                        <Route path="/portfolio-v2" component={ PortfolioV2 } />
                        <Route path="/portfolio-details" component={ PortfolioDetails } />
                        <Route path="/team" component={ Team } />
                        <Route path="/team-details" component={ TeamDetails } />
                        <Route path="/faq" component={ Faq } />
                        <Route path="/coming-soon" component={ ComingSoon } />
                        <Route path="/404" component={ Error } />
                        <Route path="/location" component={ Location } />
                   
                        <Route path="/contact" component={ Contact } />

                        <Route path="/product-details" component={ ProdductDetails } />
                        {/* blog */}
                        

	                </Switch>
	                </div>
                    </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
