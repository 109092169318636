import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import Navbar from "./global-components/navbar";
import { Helmet } from 'react-helmet';
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";


const Ser4 = () => {
  return (
    <div>
<Helmet>
        <title>Floor & wall tiling works | Flying Dragon Technical Works</title>
        <meta
          name="description"
          content="Welcome to Flying Dragon Technical Services L.L.C, a pinnacle of precision and expertise in the technical services landscape. With a comprehensive suite of offerings ranging from meticulous false ceiling installations, sanitary fittings, and facilities management to artistic engraving, painting, and carpentry works, we pride ourselves on delivering excellence at every juncture."
        />
        <meta
          name="keywords"
          content=""
        />
      </Helmet>

      <Navbar />
      <div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "} data-bs-bg={"assets/img/bg/floor.webp"} > 
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
					<div className="ltn__breadcrumb-inner">
					<h1 className="page-title">Floor & wall tiling works</h1>
					<div className="ltn__breadcrumb-list">
						<ul>
						<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
						<li>Floor & wall tiling works</li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
		</div>


      <div className="ltn__about-us-area pb-115 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                <img
                  src="assets/img/product-3/4.jpg"
                  alt="Image"
                />
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h1 className="section-title">Floor & wall tiling works</h1>
                  <p>
                  Elevate interiors with our premium
floor and wall tiling solutions. From
selection to installation, we offer a
diverse range of materials, patterns,
and finishes, tailored to enhance durability, aesthetics, and ease of
maintenance.
                  </p>
                </div>
                <div className="btn-wrapper animated">
                  <Link
                    to="/contact"
                    className="theme-btn-1 btn btn-effect-1 text-uppercase"
                  >
                    Get a Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Ser4;
