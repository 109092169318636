import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class BannerV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1">
        <div className="ltn__slider-11-inner">
          <div className="ltn__slider-11-active">
            {/* slide-item */}
            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                            <span>
                              Facilities<br></br>management
                            </span>
                            <br /> Services
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Our Facilities Management division stands as a
                              testament to our holistic approach to service
                              excellence.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              Get A QUOTE
                            </Link>
                            <Link
                              to="/Facilities-management"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/slider/61.jpg"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* slide-item */}
            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                            Sanitary installation <br />& pipes repairing
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Prioritizing functionality and efficiency, our
                              sanitary installation services ensure seamless
                              water management systems.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              GET A QUOTE
                            </Link>
                            <Link
                              to="/Sanitary-installation-pipes-repairing"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/slider/63.jpg"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* slide-item */}
            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                         
                          <h1 className="slide-title animated ">
                            False ceiling &<br /> light partition installation
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Transform your space with our specialized false
                              ceiling installations. Paired with innovative
                              lighting solutions..
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              GET A QUOTE
                            </Link>
                            <Link
                              to="/False-ceiling-light-partition-installation"
                              className="btn btn-transparent btn-effect-3"
                            >
                              READ MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/slider/62.jpg"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* slide-item */}

            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                            Engraving & <br /> ornamentation works
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Elevate interiors with our premium floor and wall
                              tiling solutions. From selection to installation,
                              we offer a diverse range of materials..
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              GET A QUOTE
                            </Link>
                            <Link
                              to="/Engraving-ornamentation-works"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/slider/s1.webp"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                            Floor and <br /> wall tiling works{" "}
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Crafted to perfection, our carpentry and wood
                              flooring solutions infuse warmth, character, and
                              timeless elegance. From custom furniture pieces to
                              intricate wood flooring installations...
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              GET A QUOTE
                            </Link>
                            <Link
                              to="/Floor-wall-tiling-works"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/product-3/4.jpg"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                           <span>Carpentry wood </span> <br /> flooring works
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Crafted to perfection, our carpentry and wood
                              flooring solutions infuse warmth, character, and
                              timeless elegance. From custom furniture pieces to
                              intricate wood flooring installations...
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              GET A QUOTE
                            </Link>
                            <Link
                              to="/Carpentry-wood-flooring-works"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/slider/64.jpg"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                           <span>Plaster</span> works
                           
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Achieve impeccable interiors with our expert
                              plaster works. Addressing structural integrity,
                              aesthetic appeal, and durability, our plastering
                              solutions ensure smooth surfaces, enhanced
                              durability, and a flawless backdrop for diverse
                              design elements.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              OUR SERVICES
                            </Link>
                            <Link
                              to="/Plaster-works"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/product-3/7.jpg"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                           <span>Glass & aluminum</span> 
                            <br />
                            installation & maintenance
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Flying Dragon Technical Works LLC specializes in
                              precision Glass and Aluminium installation,
                              bringing elegance and functionality to your
                              spaces.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              OUR SERVICES
                            </Link>
                            <Link
                              to="/Glass-aluminum-installation"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/product-3/8.jpg"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
                            <span>Electrical fittings</span>
                            <br />& fixtures repairing
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Flying Dragon Technical Works LLC specializes in
                              expert electrical fittings and fixtures repair and
                              maintenance services.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              OUR SERVICES
                            </Link>
                            <Link
                              to="/Electrical-fittings"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/product-3/9.jpg"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
			<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <h1 className="slide-title animated ">
						  <span>Wallpaper Fixing </span>
                            <br />Works
                          </h1>
                          <div className="slide-brief animated">
                            <p>
							Elevate your space with precision and expertise. Flying Dragon Technical Works LLC brings seamless wallpaper fixing, transforming..
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/contact"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              OUR SERVICES
                            </Link>
                            <Link
                              to="/Wallpaper-fixing-works"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img
                          src={publicUrl + "assets/img/product-3/10.jpg"}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>
          {/* slider-4-pagination */}
          <div className="ltn__slider-11-pagination-count">
            <span className="count" />
            <span className="total" />
          </div>
          {/* slider-sticky-icon */}
          <div className="slider-sticky-icon-2">
            <ul>
              <li>
                <a href="#" title="Facebook">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#" title="Twitter">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#" title="Linkedin">
                  <i className="fab fa-linkedin" />
                </a>
              </li>
            </ul>
          </div>
          {/* slider-4-img-slide-arrow */}
          <div className="ltn__slider-11-img-slide-arrow">
            <div className="ltn__slider-11-img-slide-arrow-inner">
              <div className="ltn__slider-11-img-slide-arrow-active">
                <div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/slider/61.jpg"}
                    alt="Flower Image"
                  />
                </div>
                <div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/slider/63.jpg"}
                    alt="Flower Image"
                  />
                </div>
                <div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/slider/62.jpg"}
                    alt="Flower Image"
                  />
                </div>
                <div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/slider/s1.webp"}
                    alt="Flower Image"
                  />
                </div>
                <div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/product-3/4.jpg"}
                    alt="Flower Image"
                  />
                </div>
                <div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/slider/64.jpg"}
                    alt="Flower Image"
                  />
                </div>
                <div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/product-3/7.jpg"}
                    alt="Flower Image"
                  />
                </div>
                <div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/product-3/8.jpg"}
                    alt="Flower Image"
                  />
                </div>
                <div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/product-3/9.jpg"}
                    alt="Flower Image" />
                </div>

				<div className="image-slide-item">
                  <img
                    src={publicUrl + "assets/img/product-3/10.jpg"}
                    alt="Flower Image" />
                </div>
              </div>
              {/* slider-4-slide-item-count */}
              <div className="ltn__slider-11-slide-item-count">
                <span className="count" />
                <span className="total" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BannerV2;
