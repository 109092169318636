import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner-v2';
import Aboutv3 from './section-components/about-v3';
import Video from './section-components/video-v2';
import ProductSlider from './section-components/product-slider-v2';
import Cateogory from './section-components/category-v2';
import Testimonial from './section-components/testimonial-v2';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Home = () => {
    return <div>
        <Navbar />
        <Banner />
        <Aboutv3 />
        <Cateogory />
        <Video />
        <ProductSlider />   
        <Testimonial />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Home

