import React from 'react';
import Navbar from './global-components/navbar';
import { Helmet } from 'react-helmet';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import Service from './section-components/service-v2';
import Apartment from './section-components/apartment-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const About_v1 = () => {
    return <div>

<Helmet>
        <title>About Us | Flying Dragon Technical Works</title>
        <meta
          name="description"
          content="Welcome to Flying Dragon Technical Services L.L.C, a pinnacle of precision and expertise in the technical services landscape. With a comprehensive suite of offerings ranging from meticulous false ceiling installations, sanitary fittings, and facilities management to artistic engraving, painting, and carpentry works, we pride ourselves on delivering excellence at every juncture."
        />
        <meta
          name="keywords"
          content=""
        />
      </Helmet>
        <Navbar />
        <PageHeader headertitle="About Us" />
        <AboutV4 />
        <Service/>
        <Apartment />
        
        <CallToActionV1 />
        <Footer />
    </div>
}

export default About_v1

