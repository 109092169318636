import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
					<div className="about-us-img-wrap about-img-left">
						<img src={publicUrl+"assets/img/others/11.png"} alt="About Us Image" />
						
					</div>
					</div>
					<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
						<h1 className="section-title">Flying Dragon<span>.</span></h1>
						<p>Welcome to Flying Dragon Technical Services L.L.C, a pinnacle of precision and expertise in the technical services landscape. With a comprehensive suite of offerings ranging from meticulous false
ceiling installations, sanitary fittings, and
facilities management to artistic engraving, painting, and carpentry works, we
pride ourselves on delivering excellence
at every juncture. Our seasoned team
blends innovation with craftsmanship,
ensuring each project resonates with
quality, durability, and aesthetic brilliance.
At Flying Dragon, we don't just offer services; we craft experiences, forging enduring relationships with our clients while
sculpting environments that inspire and
endure</p>
						</div>
						
						<div className="btn-wrapper animated">
						<Link to="/service" className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default AboutV4