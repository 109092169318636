import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ApartmentV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let CustomClass = this.props.CustomClass ? this.props.CustomClass : "";

    return (
      <div
        className={"ltn__apartments-plan-area pt-115--- pb-70 " + CustomClass}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h1 className="section-title">Vision & Mission</h1>
              </div>
              <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                <div className="nav">
                  <a
                    className="active show"
                    data-bs-toggle="tab"
                    href="#liton_tab_3_2"
                  >
                    Our Vision
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_3">
                    Our Mission
                  </a>
                  
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade active show" id="liton_tab_3_2">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg text-color-white">
                          <h2>Vision</h2>
                          <p>
                            Our vision is to be a leading force in the technical
                            services industry, recognized for our commitment to
                            innovation quality, and sustainable solutions. We
                            aspire to transform spaces empower communities, and
                            shape the futureof infrastructure by setting
                            unparalleled standards of excellence.
                          </p>
                     
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src={publicUrl + "assets/img/others/vision.webp"}
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_3">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="apartments-plan-info ltn__secondary-bg text-color-white">
                          <h2>Mission</h2>
                          <p>
                            Flying Dragon Technical Services L.L.C is to deliver
                            exceptional technical services tailored to meet the
                            evolving needs of our clients. We are dedicated to
                            leveraging our expertise, fostering innovation, and
                            cultivating lasting relationships built on trust,
                            integrity, and mutual respect.
                          </p>
                          
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="apartments-plan-img">
                          <img
                            src={publicUrl + "assets/img/others/mission.webp"}
                            alt="#"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
               
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApartmentV1;
