import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CategoryV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__category-area ltn__product-gutter section-bg-1 pt-115 pb-70">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Services</h6>
			          <h1 className="section-title">Explore Our Expert Services</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center go-top">
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/False-ceiling-light-partition-installation">
			            <span className="category-icon"><i/><img src="assets/img/icons/partition.png" className="fd-icon" /></span>
			            <span className="category-number">01</span>
			            <span className="category-title">False ceiling & light partition installation</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Sanitary-installation-pipes-repairing">
					  <span className="category-icon"><i/><img src="assets/img/icons/clean.png" className="fd-icon" /></span>
			            <span className="category-number">02</span>
			            <span className="category-title">Sanitary installation & pipes repairing</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Facilities-management">
					  <span className="category-icon"><i/><img src="assets/img/icons/facility-management.png" className="fd-icon" /></span>
			            <span className="category-number">03</span>
			            <span className="category-title">Facilities management</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Floor-wall-tiling-works">
					  <span className="category-icon"><i/><img src="assets/img/icons/work.png" className="fd-icon" /></span>
			            <span className="category-number">04</span>
			            <span className="category-title">Floor & wall tiling works</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Engraving-ornamentation-works">
					  <span className="category-icon"><i/><img src="assets/img/icons/laser.png" className="fd-icon" /></span>
			            <span className="category-number">05</span>
			            <span className="category-title">Engraving & ornamentation works</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Painting-contracting">
					  <span className="category-icon"><i/><img src="assets/img/icons/paint-roller.png" className="fd-icon" /></span>
			            <span className="category-number">06</span>
			            <span className="category-title">Painting & Contracting</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Plaster-works">
					  <span className="category-icon"><i/><img src="assets/img/icons/build.png" className="fd-icon" /></span>
			            <span className="category-number">07</span>
			            <span className="category-title">Plaster Works</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Glass-aluminum-installation">
					  <span className="category-icon"><i/><img src="assets/img/icons/window.png	" className="fd-icon" /></span>
			            <span className="category-number">08</span>
			            <span className="category-title">Glass & aluminum installation & maintenance</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
				  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Electrical-fittings">
					  <span className="category-icon"><i/><img src="assets/img/icons/flash.png" className="fd-icon" /></span>
			            <span className="category-number">08</span>
			            <span className="category-title">Electrical fittings & fixtures repairing</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
				  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Wallpaper-fixing-works">
					  <span className="category-icon"><i/><img src="assets/img/icons/wallpaper.png" className="fd-icon" /></span>
			            <span className="category-number">08</span>
			            <span className="category-title">Wallpaper Fixing Works </span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
				  <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/Carpentry-wood-flooring-works">
					  <span className="category-icon"><i/><img src="assets/img/icons/hammer.png" className="fd-icon" /></span>
			            <span className="category-number">08</span>
			            <span className="category-title">Carpentry & wood flooring works</span>
			            <span className="category-brief">
			             
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default CategoryV2