import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import ServiceV1 from './section-components/service-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';



const Ser2 = () => {
    return (
        <div>
<Helmet>
        <title>Sanitary installation & pipes repairing | Flying Dragon Technical Works</title>
        <meta
          name="description"
          content="Welcome to Flying Dragon Technical Services L.L.C, a pinnacle of precision and expertise in the technical services landscape. With a comprehensive suite of offerings ranging from meticulous false ceiling installations, sanitary fittings, and facilities management to artistic engraving, painting, and carpentry works, we pride ourselves on delivering excellence at every juncture."
        />
        <meta
          name="keywords"
          content=""
        />
      </Helmet>

            <Navbar />

            <div className={"ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "} data-bs-bg={"assets/img/bg/sani.webp"} > 
			<div className="container">
				<div className="row">
				<div className="col-lg-12">
					<div className="ltn__breadcrumb-inner">
					<h1 className="page-title">Sanitary installation & pipes repairing</h1>
					<div className="ltn__breadcrumb-list">
						<ul>
						<li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
						<li>Sanitary installation & pipes repairing</li>
						</ul>
					</div>
					</div>
				</div>
				</div>
			</div>
		</div>

            <div className="ltn__about-us-area pb-115 go-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 align-self-center">
                            <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                                <img src="assets/img/product-3/2.jpg" alt="Image" />
                            </div>
                        </div>
                        <div className="col-lg-7 align-self-center">
                            <div className="about-us-info-wrap">
                                <div className="section-title-area ltn__section-title-2--- mb-20">
                                    <h1 className="section-title">Sanitary installation &  <span>pipes repairing</span></h1>
                                    <p>Prioritizing functionality and efficiency, our sanitary installation services ensure seamless water management systems.
Additionally, our expert pipe repairing solutions address leaks,
blockages, and maintenance, ensuring long-lasting performance and peace of mind.</p>
                                </div>
                                <div className="btn-wrapper animated">
                                    <Link to="/contact" className="theme-btn-1 btn btn-effect-1 text-uppercase">Get a Quote</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
            <CallToActionV1 />
            <Footer />
        </div>
    );
}

export default Ser2;


