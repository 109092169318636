import React from 'react';
import Navbar from './global-components/navbar';
import { Helmet } from 'react-helmet';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const ContactV1 = () => {
    return <div>
        <Helmet>
        <title>Contact Us | Flying Dragon Technical Works</title>
        <meta
          name="description"
          content="Welcome to Flying Dragon Technical Services L.L.C, a pinnacle of precision and expertise in the technical services landscape. With a comprehensive suite of offerings ranging from meticulous false ceiling installations, sanitary fittings, and facilities management to artistic engraving, painting, and carpentry works, we pride ourselves on delivering excellence at every juncture."
        />
        <meta
          name="keywords"
          content=""
        />
      </Helmet>
        <Navbar />
        <PageHeader headertitle="Contact Us" subheader="Contact" />
        <ContactInfo />
        <ContactForm />
        <Map />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default ContactV1

