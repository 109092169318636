import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';

class Footer_v1 extends Component {

    componentDidMount() {

    	const $ = window.$;
    	
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

         $('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

				$(window).scrollTop(0);

			setTimeout(function(){
			    	$(".quarter-overlay").fadeOut(300);
				}, 800);

        });


		$(document).on('click','.theme-btn-1 ', function(){ 
            $( 'div' ).removeClass( 'modal-backdrop' );
            $( 'div' ).removeClass( 'show' );
            $( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
        });
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = "Footer logo"

        return (
				<footer className="ltn__footer-area  ">
				  <div className="footer-top-area  section-bg-2 plr--5">
				    <div className="container">
				      <div className="row">
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-about-widget">
				            
				            <p><b>Flying Dragon Technical Services L.L.C </b> </p>
				            <div className="footer-address">
				              <ul>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-placeholder" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p>China cluster F-11, shop No-12, International City - Dubai, UAE</p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-call" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="tel:+971588893188 ">+971 58 8893188 </a></p>
				                  </div>
				                </li>
								<li>
				                  <div className="footer-address-icon">
				                    <i className="icon-call" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="tel:+971564066522">+971 56 4066522</a></p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-mail" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="mailto:info@flyingdragon.ae">info@flyingdragon.ae </a></p>
				                  </div>
				                </li>
				              </ul>
				            </div>
				            <div className="ltn__social-media mt-20">
						    	<Social />
				            </div>
				          </div>
				        </div>
				   
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">Services</h4>
				            <div className="footer-menu go-top">
				              <ul>
				                <li><Link to="/False-ceiling-light-partition-installation">False ceiling & light partition installation</Link></li>
				                <li><Link to="/Sanitary-installation-pipes-repairing">Sanitary installation & pipes repairing</Link></li>
				                <li><Link to="/Facilities-management">Facilities management</Link></li>
				                <li><Link to="/Floor-wall-tiling-works">Floor & wall tiling workst</Link></li>
				                <li><Link to="/Engraving-ornamentation-works">Engraving & ornamentation works</Link></li>
				                <li><Link to="/Painting-contracting">Painting & Contracting</Link></li>
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">&nbsp;</h4>
				            <div className="footer-menu go-top">
				              <ul>
				                <li><Link to="/Painting-contracting">Plaster Works</Link></li>
				                <li><Link to="/Glass-aluminum-installation">Glass & aluminum installation & maintenance</Link></li>
				                <li><Link to="/Electrical-fittings">Electrical fittings & fixtures repairing</Link></li>
				                <li><Link to="/Wallpaper-fixing-works">Wallpaper Fixing Works</Link></li>
				                <li><Link to="/Carpentry-wood-flooring-works">Carpentry & wood flooring works</Link></li>
				                
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-12 col-12">
				          <div className="footer-widget footer-newsletter-widget">
				            <h4 className="footer-title">Newsletter</h4>
				            <p>Subscribe to our weekly Newsletter and receive updates via email.</p>
				            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7222.157778458073!2d55.40924264462383!3d25.16681395990403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f61461dfe5d3b%3A0x7b1a20c57bed4f41!2sInternational%20City%20-%20China%20Cluster%20-%20Dubai!5e0!3m2!1sen!2sae!4v1705319604923!5m2!1sen!2sae" width="100%" height="200px" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
				          
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				  <Copyright />
				  <div className="phone-call cbh-phone cbh-green cbh-show  cbh-static" id="clbh_phone_div"><a id="WhatsApp-button" href="https://api.whatsapp.com/send?phone=971588893188&text=Hi, For Enquiry." target="_blank" class="phoneJs" title="WhatsApp 360imagem"><div className="cbh-ph-circle"></div><div className="cbh-ph-circle-fill"></div><div className="cbh-ph-img-circle1"></div></a></div>
				</footer>

				
        )
    }
}


export default Footer_v1