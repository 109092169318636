import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="google-map mb-120">
       
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7222.157778458073!2d55.40924264462383!3d25.16681395990403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f61461dfe5d3b%3A0x7b1a20c57bed4f41!2sInternational%20City%20-%20China%20Cluster%20-%20Dubai!5e0!3m2!1sen!2sae!4v1705319604923!5m2!1sen!2sae" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
		</div>
        }
}

export default Map